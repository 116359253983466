<template>
    <div class="main-list--view">
        <div class="navbar">
            <button class="btn btn__action" @click="goToRegister">СОЗДАТЬ ПОЛЬЗОВАТЕЛЯ</button>
            <button class="btn btn__img" @click="exit"><img class="icon__exit" src="../../assets/icons/exit.svg">ВЫХОД</button>

        </div>
        <div class="main--view">
            <div class="search--container">
                <div class="input--container">
                    <a class="icon-search"></a>
                    <input placeholder="Поиск" v-model="searchText">
                </div>
            </div>
            <div class="users--list-container">
                <p>{{users.length}} пользователей</p>
                <div class="users--list">
                    <div class="user--list-item"  v-for="user of users" v-bind:key="user.id" @click="goToUser(user)" :class="{'multi' : user.type === 2}">
                        <div class="account-info--container">
                            <div class="avatar--container">
                                <img :src="user.avatar">
                            </div>
                            <div class="name--container">
                                <p class="name__text">{{user.name}}</p>
                                <p class="category__text">{{getUsersCategory(user)}}</p>
                            </div>
                        </div>
                        <div class="indicator--container">
                            <p v-if="user.type !=2"><a class="icon-camera"></a>{{user.camerasCount}}</p>
                          <p v-else><a class="icon-camera"></a>{{getSumCameras(user)}}</p>

                          <p><a class="icon-finance"></a>0</p>
                            <p v-if="user.type === 2"><a class="icon-acc"></a>{{(user.childUsers != null) ? user.childUsers.length : 0}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "MainUsersListView",
      data(){
          return {
            searchText: ''
          }
      },
        computed:{
            users(){
                const users = this.$store.getters.USERS
              if (this.searchText.length === 0){
                return users
              }


              return users.filter(user => user.name.toLowerCase().includes(this.searchText.toLowerCase()) || this.getUsersCategory(user).toLowerCase().includes(this.searchText.toLowerCase()))
            }
        },
        methods:{
            getUsersCategory(user){
                if (user.type === 2){
                    let types = []
                    for (let us of user.childUsers){
                        types.push(us.typeName)
                    }
                  return Array.from(new Set([...types])).join(",")
                } else {
                    return user.typeName
                }
            },

          getSumCameras(user){
              let camsCount = 0
              for (user of user.childUsers){
                camsCount+= user.camerasCount
              }


              return camsCount;
          },

            goToUser(user){
                if (user.type === 2){
                    this.$router.push({name : "MultiUserView",params : {id : user.id}})
                } else {
                    this.$router.push({name : "UserView",params : {id : user.id}})
                }
            },

            goToRegister(){
                this.$router.push({name : "Register"})
            },

          exit(){
              localStorage.clear();
              window.location.href = "/auth"
          }
        },
        async mounted(){
            await this.$store.dispatch("GET_ALL_USERS");

        }
    }
</script>

<style scoped lang="scss">
    .navbar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 38px;
        margin-left: 70px;
        margin-right: 70px;

        @include for-desktop-up{
            margin-top: 20px;
            margin-left: 20px;
            margin-right: 20px;
        }
        .btn{
            width: 100px;
            height: 21px;
            background: #6F64F8;
            border-radius: 4px;
            font-family: Roboto, "sans-serif";
            font-size: 8px;
            letter-spacing: 0;
            border: 0;
            outline: 0;
            cursor: pointer;

            @include for-big-desktop-up{
                width: calc(366px * 0.66);
                height: calc(48px * 0.75);
                border-radius: 6px;
                font-size: calc(16px - 4px);
                letter-spacing: 0;
            }

            @include for-desktop-up{
                width: 180px;
                height: 26px;
                border-radius: 4px;
                font-size: 11px;
            }

            &:hover{
                filter: brightness(87.5%);
            }

            &.not_available{
                pointer-events: none;
                opacity: .5;
            }
        }

        .btn__action{
            background: #7E72F2;
            color: #FFFFFF;
        }

        .btn__img{
            background: #7E72F2;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;

            @include for-big-desktop-up{
                width: calc(200px * 0.66);
              height: calc(48px * 0.75);
            }

            @include for-desktop-up{
                width: 100px;
                height: 26px;
                border-radius: 4px;
                font-size: 11px;


            }

            img{
                margin-right: calc(23px * 0.66);
                width: calc(20px * 0.66);


                @include for-desktop-up{
                    margin-right: 9px;
                    width: 10px;
                }
            }

        }

        .btn__close{
            background: #EDEDED;
            color: #2C2C2C;
            margin-right: 15px;

            @include for-big-desktop-up{
                margin-right: 30px;
            }
        }
    }

    .main--view{
        margin-left: 70px;
        margin-top: 26px;

        @include for-desktop-up{
            margin-left: 20px;
            margin-top: 11px;
        }
        .search--container{
            .input--container{
                background: #FFFFFF;
                border: 1px solid #EBEBEB;
                width: calc(610px * 0.66);
                height: calc(50px * 0.75);
                display: flex;
                align-items: center;

                @include for-desktop-up{
                    width: 300px;
                    height: 26px;
                }

                a{
                    margin-left: 21px;


                    @include for-desktop-up{
                        margin-left: 10px;
                        font-size: 8px;
                    }
                }

                input{
                    margin-left: 13px;
                    width: calc(550px * 0.75);
                    font-family: Roboto, "sans-serif";
                    font-size: 14px;
                    color: black;
                    letter-spacing: 0;
                    outline: 0;
                    border: 0;

                    @include for-desktop-up{
                        margin-left: 6px;
                        width: 270px;
                        font-size: 9px;
                    }
                }

                input::placeholder{
                    color: #999999;
                }
            }
        }

        .users--list-container{
            margin-top: 15px;

            @include for-desktop-up{
                margin-top: 7px;
            }

            p{
                font-family: Roboto, "sans-serif";
                font-size: 15px;
                color: #2C2C2C;
                letter-spacing: 0;
                margin-left: 20px;

                @include for-desktop-up{
                    font-size: 8px;
                    margin-left: 10px;

                }
            }

            .users--list{
                width: calc(611px*0.66);
                height: calc(100vh - 250px);
                margin-top: 26px;
              overflow-y: scroll;

                @include for-desktop-up{
                    width: 305px;
                    margin-top: 13px;
                }
                .user--list-item{
                    width: calc(610px*0.66);
                    background: #FFFFFF;
                    border: 1px solid #EBEBEB;
                    height: calc(100px * 0.75);
                    cursor: pointer;


                    @include for-desktop-up{
                        width: 305px;
                        height: 50px;
                    }

                    &:hover{
                        filter: brightness(95%);
                    }


                    .account-info--container{
                        margin-top: calc(18px * 0.75);
                        margin-left: calc(18px * 0.75);
                        display: flex;

                        @include for-desktop-up{
                            margin-top: 9px;
                            margin-left: 9px;
                        }

                        .avatar--container{
                            width: calc(37px * 0.75);
                            height: calc(37px * 0.75);
                            object-fit: cover;
                            border-radius: 50%;

                            @include for-desktop-up{
                                width: 19px;
                                height: 19px;
                            }

                            img{
                              width: calc(37px * 0.75);
                              height: calc(37px * 0.75);
                                object-fit: cover;
                                border-radius: 50%;

                                @include for-desktop-up{
                                    width: 19px;
                                    height: 19px;
                                }
                            }
                        }

                        .name--container{
                            margin-left: calc(26px * 0.75);

                            @include for-desktop-up{
                                margin-left: 12px;

                            }
                            .name__text{
                                font-family: Roboto, "sans-serif";
                                font-size: 12px;
                                color: #2C2C2C;
                                letter-spacing: 0;
                                margin-left: 0px;

                                @include for-desktop-up{
                                    font-size: 7px;
                                }
                            }

                            .category__text{
                                font-family: Roboto, "sans-serif";
                                font-size: 10px;
                                color: #2C2C2C;
                                letter-spacing: 0;
                                margin-left: 0px;
                                margin-top: 7px;

                                @include for-desktop-up{
                                    margin-top: 4px;
                                    font-size: 6px;

                                }
                            }
                        }

                    }

                    .indicator--container{
                        margin-left: calc(18px * 0.75);
                        margin-top: calc(16px * 0.75);
                        display: flex;
                        align-items: center;


                        @include for-desktop-up{
                            margin-left: 9px;
                            margin-top: 7px;

                        }


                        .icon-finance{
                            font-size: 10px;

                            @include for-desktop-up{
                                font-size: 6px;
                            }
                        }

                        .icon-acc{
                            font-size: 14px;

                            @include for-desktop-up{
                                font-size: 8px;
                            }
                        }

                        .icon-camera{
                            font-size: 10px;

                            @include for-desktop-up{
                                font-size: 6px;
                            }
                        }

                        a{
                            margin-right: 10px;

                            @include for-desktop-up{
                                margin-right: 5px;
                            }
                        }

                        p{
                            opacity: 0.9;
                            font-family: Roboto, "sans-serif";
                            font-size: 12px;
                            color: #525252;
                            letter-spacing: 0.54px;
                            margin-left: 20px;

                            @include for-desktop-up{
                                margin-left: 10px;
                                font-size: 6px;

                            }

                        }

                        p:first-child{
                            margin-left: 0;
                        }
                    }

                    &.multi{
                        background: #FFFEF2;
                    }
                }

            }
        }
    }

    .icon-search{
        color: black;
    }
</style>
